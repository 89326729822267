<template>
	<div v-if="competition && Object.keys(layout).length > 0" class="container">
		<ValidationObserver v-slot="{ handleSubmit }" ref="registerFormObserver">
			<form :class="{ 'is-waiting': isWaiting }" @submit.prevent="handleSubmit(registerSubmit)">
				<div class="register-step" v-if="currentFormStep === 'playerData'">
					<div class="register-header">
						<div class="left">
							<h3>Zapisz się do zawodów</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>
					<div class="register-step-section">
						<div class="row">
						<div class="col-12 col-lg-3">
							<div class="register-step-header">
							<h4>Dane uczestnika</h4>
							</div>
						</div>
						<div class="col-12 col-lg-9">
							<div class="section-wrap bg--gray">
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.firstname" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.firstname"
											:error.sync="errors[0]"
											:label="say.firstname"
											border
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.lastname" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.lastname"
											:error.sync="errors[0]"
											:label="say.lastname"
											border
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.email" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.email"
											:error.sync="errors[0]"
											:label="say.email"
											border
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="row p4">
										<div class="col-4">
											<!-- Phone prefix. -->
											<ValidationProvider name="playerData.phonePrefix" rules="required" v-slot="{ errors }" class="scroll-margin">
												<base-input-select
													v-model="form.user.phonePrefix"
													:reduce="(prefix) => prefix.id"
													:clearable="false"
													:options="prefixes"
													:error.sync="errors[0]"
													:label="say.prefix"
													class="no-border"
												/>
											</ValidationProvider>
										</div>
										<div class="col-8">
											<!-- Phone number. -->
											<ValidationProvider name="playerData.phone" rules="required" v-slot="{ errors }" class="scroll-margin">
												<base-input-text
													v-model="form.user.phone"
													:label="say.phone"
													:error.sync="errors[0]"
												/>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.gender" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-radio
											v-model="form.user.gender"
											:label="say.gender"
											:options="[
												{ value: 'M', title: 'man' },
												{ value: 'K', title: 'woman' },
											]"
											:error.sync="errors[0]"
											class="no-border"
										/>
									</ValidationProvider>
								</div>
							</div>

							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.birthDate" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-date
											v-model="form.user.birthDate"
											:error.sync="errors[0]"
											:label="say.birthtime"
											initialView="year"
											:openDate="new Date('1983-01-01')"
											format="yyyy.MM.dd"
											class="no-border"
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.city" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.city"
											:label="say.city"
											:error.sync="errors[0]"
										/>
										<!-- 
											<base-input-autocomplete
											v-model="searchValueCity"
											class="user-form__autocomplete"
											:label="say.city"
											:error.sync="errors[0]"
											:clear-search-on-select="true"
											action="AUTOCOMPLETE_LOCATION"
											@select="selectCity($event)"
											/>
										-->
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.nationality" rules="required" v-slot="{ errors }" class="scroll-margin">
										<base-input-select
											v-model="form.user.nationality"
											:options="countries"
											:clearable="false"
											:label="say.nationality"
											:reduce="(country) => country.id"
											:error.sync="errors[0]"
											class="no-border"
										/>            
									</ValidationProvider>      
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="playerData.clubname" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.clubname"
											:label="say.club"
											:error.sync="errors[0]"
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<!-- Phone number. -->
									<ValidationProvider name="playerData.icePhone" v-slot="{ errors }" class="scroll-margin">
										<base-input-text
											v-model="form.user.icePhone"
											:label="say.phone_ice"
											:error.sync="errors[0]"
										/>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<base-input-checkbox v-model="form.user.isAnonymous" :checkbox-value="false" name="anonymous" wrap label="Ukrycie uczestnictwa">Uczestnictwo anonimowe</base-input-checkbox>
								</div>
								<div class="col-12 col-md-6">
									<p class="hint">Zaznacz, aby ukryć swoje dane w serwisie DOSTARTU. Uwaga - Twoje dane będą widoczne z podpisem “Uczestnik anonimowy”. </p>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					<!-- 
					<div class="register-step-section">
						<div class="row">
						<div class="col-12 col-lg-3">
							<div class="register-step-header">
							<h4>Załóż nowe konto</h4>
							<p>Wpisz hasło, aby założyć nowe konto i mieć dostęp do wszystkich zawodów, w których bierzesz lub brałeś udział.</p>
							</div>
						</div>
						<div class="col-12 col-lg-9">
							<div class="section-wrap bg--gray">
							<div class="row">
								<div class="col-12 col-md-6">
								<base-input-text
									v-model="form.password"
									:error.sync="errors.password"
									:label="say.password"
									border
								/>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>       
					-->
				</div>
				<div class="register-step" v-if="currentFormStep === 'classification'">
					<div class="register-header">
						<div class="left">
							<h3>Wybierz klasyfikacje</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>    
					<div class="register-step-section">
						<div class="row">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<ValidationProvider name="form.classificationId" rules="required" v-slot="{ errors }" class="scroll-margin">
									<input type="hidden" v-model="form.classificationId" />
									<Classification v-for="classification in form.classifications" 
										:key="classification.id"
										:id="classification.id"
										:classification="classification"
										:competition-id="id"
										@input="(data) => {
											form.classificationId = parseInt(data.value)
											form.price = parseInt(data.price).toFixed(2)
										}"
										:playerAge="birthAge"
										:checked="form.classificationId === classification.id"
										:error.sync="errors[0]"
									/>
									<span v-if="errors[0]" class="validation-text">{{ say[errors[0]] }}</span>
								</ValidationProvider>
							</div>
						</div>
					</div> 
				</div> 
				<div class="register-step" v-if="currentFormStep === 'specialCategories'">
					<div class="register-header">
						<div class="left">
							<h3>Kategorie specjalne</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>    
					<div class="register-step-section">
						<div class="row">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<SpecialCategory v-for="category in form.specialCategories" 
									:key="category.id"
									:id="category.id"
									:category="category"
									@input="(data) => {
										form.specialCategorieId = parseInt(data.value)
										form.price = parseInt(data.price).toFixed(2)
									}"
									:checked="form.specialCategorieId === category.id"
									:error.sync="errors[0]"
								/>
							</div>
						</div>
					</div> 
				</div> 
				<div class="register-step" v-if="currentFormStep === 'inputs'">
					<div class="register-header">
						<div class="left">
							<h3>Pola dodatkowe</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>
					<div class="register-step-section">
						<div class="row">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<div class="section-wrap bg--gray">
									<div v-if="inputsAdditionalInformation && inputsAdditionalInformation.length > 0">
										<ValidationProvider v-for="(input, index) in inputsAdditionalInformation" :name="'playerInputs'+input.id+'_'+index+'wrapAddUsr'" :rules="{ 'required' : input.fRequired }" v-slot="{ errors }" :key="input.id+'_'+index+'wrapAddUsr'" class="scroll-margin">
											<panel-input-wrap :data="input" v-model="form.playerInputs[input.id].value" :errors="errors[0]" :options="{ fr: {wrap: true }}" />
										</ValidationProvider>
									</div>
									<div v-if="inputsSupportFormUser && inputsSupportFormUser.length > 0">
										<ValidationProvider v-for="(input, index) in inputsSupportFormUser" :name="'playerInputs'+input.id+'_'+index+'wrapAddSuppUsr'" :rules="{ 'required' : { allowFalse: !input.fRequired } }" v-slot="{ errors }" :key="input.id+'_'+index+'wrapAddSuppUsr'" class="scroll-margin">
											<panel-input-wrap :data="input" v-model="form.playerInputs[input.id].value" :errors="errors[0]" :options="{ fr: {wrap: true }}" />
										</ValidationProvider>
									</div>
									<div v-if="inputsConfirmationInformation && inputsConfirmationInformation.length > 0">
										<ValidationProvider v-for="(input, index) in inputsConfirmationInformation" :name="'playerInputs'+input.id+'_'+index+'wrapAddConfUsr'" :rules="{ 'required' : { allowFalse: !input.fRequired } }" v-slot="{ errors }" :key="input.id+'_'+index+'wrapAddConfUsr'" class="scroll-margin">
											<panel-input-wrap :data="input" v-model="form.playerInputs[input.id].value" :errors="errors[0]" />
										</ValidationProvider>
									</div>
								</div>
								<div v-if="form.totalPrice" class="register-summary-info" style="margin-top: 16px;">
									<div>
										<div><h4>Do zapłaty</h4></div>
										<h4>{{ form.totalPrice }} zł</h4>
									</div>
								</div>
							</div>
						</div>
					</div> 
				</div>
				<div class="register-step" v-if="currentFormStep === 'insurance'">
					<div class="register-header">
						<div class="left">
							<h3>Ubezpiecz swój start <span class="text-success text-normal">(opcjonalnie)</span></h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>
					<div class="register-step-section">
						<div v-for="insurance in insuranceProducts" :key="insurance.id" class="row">
							<div class="col-12 col-lg-3">
								<div class="register-step-header">
								<h4>{{ insurance.name }}</h4>
								</div>
							</div>
							<div class="col-12 col-lg-9">
								<div class="section-wrap bg--gray">
								<div class="row">
									<div class="col-12 col-md-6 col-lg-7">
									<base-input-checkbox v-model="form.insurances" :checkbox-value="insurance.id" :value="insurance.id" name="insurances" wrap>
										<h4>{{ insurance.name }} <span>{{ insurance.packages[0].price | asPrice }}</span></h4>
										<p v-if="insurance.id === 4">
										maksymalny zwrot kosztów rehabilitacji {{ insurance.packages[0].max_refund | asNumberFormat }}
										</p>
										<p v-else-if="insurance.id === 5">
										Złamania (max. suma ubezpieczenia {{ insurance.packages[0].max_bone_group_3 | asNumberFormat }}), urazy (max. suma
										ubezpieczenia {{ insurance.packages[0].max_injury_group_3 | asNumberFormat }}), śmierć w wyniku nieszczęśliwego wypadku (suma
										ubezpieczenia {{ insurance.packages[0].max_death_refund | asNumberFormat }}). <br><br>Okres ubezpieczenia: od godziny startu w
										wydarzeniu sportowym do przekroczenia linii mety.
										</p>
										<p v-else-if="insurance.id === 6">
										maksymalny zwrot kosztów rezygnacji z zawodów {{ insurance.packages[0].max_resignation_refund | asNumberFormat }}
										</p>
									</base-input-checkbox>
									</div>
								</div>
								</div>
							</div>
						</div>
						<div v-if="form.totalPrice" class="row">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<div class="register-summary-info">
									<div>
										<div><h4>Do zapłaty</h4></div>
										<h4>{{ form.totalPrice }} zł</h4>
									</div>
								</div>
							</div>
						</div>
					</div> 
				</div>
				<div class="register-step" v-if="currentFormStep === 'shop'">
					<div class="register-header">
						<div class="left">
							<h3>Sklep</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div>
					<div class="register-step-section">
						<div v-if="shopProducts && shopProducts.length > 0" class="register-shop-items">
							<template v-for="(product, index) in shopProducts.filter((i) => i.visible)">
								<div class="register-shop-item">
									<div v-if="product.images && product.images.length > 0 && product.images[0].visible" class="register-shop-img">
										<img :src="`https://dostartu.pl/files/shop/products/images/${product.images[0].filename}`" />
									</div>
									<div class="register-shop-details">
										<div class="register-shop-name">{{ product.name }}</div>
										<div class="register-shop-price">{{ product.price }} zł</div>
									</div>
									<div v-if="product.models && product.models.length > 0" class="register-shop-variants">
										<div v-for="model in product.models.filter((i) => i.visible)" class="register-shop-variants-item">
											<div class="register-shop-variants__name">{{ model.name }}</div>
											<QuantityInput 
												:product="product" 
													:model="model" 
													@quantity-change="handleQuantityChange"
													:value="getShopProductValue(product.id, model.id)"
											/>

										</div>
									</div>
									<div v-else class="register-shop-variants">
										<div class="register-shop-variants-item">
											<div class="register-shop-variants__name">&nbsp;</div>
											<QuantityInput 
												:product="product" 
												@quantity-change="handleQuantityChange"
												:value="getShopProductValue(product.id)"
											/>
										</div>
									</div>
									<p v-if="product.description" class="register-shop-description">{{ product.description }}</p>
								</div>
							</template>
						</div>  
						<div v-if="form.totalPrice" class="row" style="margin-top: 16px">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<div class="register-summary-info">
									<div>
										<div><h4>Do zapłaty</h4></div>
										<h4>{{ form.totalPrice }} zł</h4>
									</div>
								</div>
							</div>
						</div>
					</div> 
				</div>
				<div class="register-step" v-if="currentFormStep === 'summary'">
					<div class="register-header">
						<div class="left">
							<h3>Podsumowanie</h3>
						</div>
						<Progress :items="allFormSteps" :current="currentFormStep" />
					</div> 
					<div class="register-step-section">
						<div class="row">
							<div class="col-12 col-lg-3">
								<div class="user-summary">
									<div>
										<label>{{ say.firstname }} {{ say.lastname }}</label>
										<b>{{ form.user.firstname }} {{ form.user.lastname }}</b>
									</div>
									<div>
										<label>{{ say.email }}</label>
										<b>{{ form.user.email }}</b>
									</div>										
									<div>
										<label>{{ say.birthtime }}</label>
										<b>{{ new Date(form.user.birthDate).toLocaleDateString() }}</b>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-9">
								<div v-for="item in form.items" :key="item.itemData.id" class="register-summary-info">
									<div v-if="item.itemData.itemType === 'competition'">
										<div><h4>{{ item.itemData.name || 'competition' }}</h4></div>
										<h4 v-if="item.price">{{ item.price }} zł</h4>
									</div>
									<div v-else-if="item.itemData.itemType === 'classification'">
										<div>
											<h4>{{ item.itemData.name || 'classification' }}</h4>
											<p v-if="item.itemData.specialCategory">{{ say.special_category }}: {{ item.itemData.specialCategory }}</p>
										</div>
										<h4 v-if="item.price">{{ item.price }} zł</h4>
									</div>
									<div v-else-if="item.itemData.itemType === 'shopProduct'">
										<div><h4>{{ item.itemData.name || 'shopProduct' }}</h4></div>
										<h4>
											<span v-if="item.itemData.quantity" style="font-size: 14px;font-weight: 400;">{{ item.itemData.quantity }}x&nbsp;</span>
											<span v-if="item.price">{{ item.price }} zł</span>
										</h4>
									</div>
									<div v-else-if="item.itemData.itemType === 'insurance'">
										<div><h4>{{ item.itemData.name || 'insurance' }}</h4></div>
										<h4>
											<span v-if="item.itemData.quantity" style="font-size: 14px;font-weight: 400;">{{ item.itemData.quantity }}x&nbsp;</span>
											<span v-if="item.price">{{ item.price }} zł</span>
										</h4>
									</div>
									<div v-if="Object.keys(item.itemData.extraAttributes).length > 0">
										{{ item.itemData.extraAttributes.modelName }}
									</div>
								</div>
								<div  class="register-summary-info">
									<div>
										<div><h4>Do zapłaty</h4></div>
										<h4>{{ form.totalPrice }} zł</h4>
									</div>
								</div>
								<div style="margin-top: 24px">
									<div class="register-summary-info">
										<ValidationProvider :rules="{ 'required' : { allowFalse: false } }" name="regulations_0" v-slot="{ errors }" class="scroll-margin">
											<base-input-checkbox v-model="form.regulations.reg0" :checkbox-value="1" name="regulations_0" wrap>
												{{ say.i_agree_with_data_processing_dostartu | asReplaceShortcode('organizer', organizer.name) }}
											</base-input-checkbox>
											<span v-if="errors[0]" class="validation-text">{{ say[errors[0]] }}</span>
										</ValidationProvider>
									</div>
									<ValidationProvider v-for="(reg, index) in regulations" :key="index+1" :rules="{ 'required' : { allowFalse: false } }":name="`regulations_${index+1}`" v-slot="{ errors }" class="scroll-margin">
										<div class="register-summary-info"  style="margin-top: 8px">
											<base-input-checkbox  v-model="form.regulations[`reg${index+1}`]" :checkbox-value="1" :value="index+1" :name="`regulations_${index+1}`" wrap>
												{{ say.i_agree_with_competition_statute }} <a :href="reg" target="_blank">{{ reg }}</a>
											</base-input-checkbox>
											<span v-if="errors[0]" class="validation-text">{{ say[errors[0]] }}</span>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</div>
				</div> 
				<div class="register-step status" v-if="currentFormStep === 'status'">
					<!-- 
					<div class="register-status">
						<div class="register-status-image">
							<img src="@/assets/images/icons/waiting.svg" alt="">
						</div>
						<div class="register-status-content">
							<h3>Płatność jest przetwarzana.</h3>
							<p>Czekamy na odpowiedź od operatora płatności, o statusie transakcji.</p>
							<p>Potwierdzenie płatności zwykle trwa kilka minut.
							Możesz spokojnie zamknąć tę stronę. O zmianie statusu płatności poinformujemy Cię w wiadomości
							e-mail.</p>
						</div>
					</div>
					-->
					<div class="register-status">
						<div class="register-status-image">
							<img src="@/assets/images/icons/success.svg" alt="">
						</div>
						<div class="register-status-content">
							<h3 class="text-success">Dziękujemy za zgłoszenie udziału.</h3>
							<p>Przypominamy, że data startu to <b>{{ competition.startedTime | asTime }}</b></p>
							<p>Lokalizacja <b>{{ competition.location }}</b></p>
							<base-button
								v-if="
									(competition.status <= 3 || competition.isRemote) &&
										![4439, 4094, 4095, 5165, 8515, 8502, 8519, 8520, 8526].includes(competition.id)
								"
								btn2
								class="minisite__button"
								@click="
									$router.push({
										name: 'minisite-list',
										params: competition,
										alias: true,
									})
								"
							>
								{{ say.start_list }}
							</base-button>
						</div>
					</div>
					<!-- 
					<div class="register-status">
						<div class="register-status-image">
							<img src="@/assets/images/icons/failed.svg" alt="">
						</div>
						<div class="register-status-content">
							<h3 class="text-error">Coś poszo nie tak i nie udało się zrealizować płatności. </h3>
							<p>Spróbuj dokonać płatności jeszcze raz.</p>
							<base-button btn1>OPŁAĆ UDZIAŁ</base-button>
							<p>E-mail organizatora <b>biuro@spla.com.pl</b></p>
							<p>Telefon organizatora <b>123 456 789</b></p>
						</div>
					</div>
				-->
				</div>
				
				<div v-if="currentFormStep !== 'status'" class="register-step-section">
					<div class="row">
							<div class="col-12 col-lg-3">&nbsp;</div>
							<div class="col-12 col-lg-9">
								<div class="register-step-buttons row">
									<div class="order-2 col-12 col-lg-3">
										<base-button v-if="currentFormStep === 'playerData'" btn4 @click="$router.back()" :class="{ tc2bo : layout.colourDefault }" :style="styleObject">
										{{ say.cancel }}
									</base-button>
									<base-button v-else btn4 @click="prevFormStep"  :class="{ tc2bo : layout.colourDefault }" :style="styleObject">
									Cofnij
								</base-button>
							</div>              
							<div class="order-1 col-12 col-lg-4">
								<base-button btn1 @click="registerSubmit" :class="{ tc1bg: layout.colourDefault }" :style="styleObject">
									<template v-if="currentFormStep === 'summary' && form.totalPrice">{{ say.submit_form }}</template>
									<template v-else-if="currentFormStep === 'summary'">{{ say.save }}</template>
									<template v-else>
										{{ say.step_next }}
									</template>
								</base-button>
							</div>
						</div>            
						</div>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import api from '@/api';
import { mapGetters, mapActions } from "vuex";
import {
  READ_NEXT_IN_CYCLE_COMPETITIONS,
  READ_COMPETITION_CLASSIFICATIONS,
  BOOTSTRAP_MINISITE,
  READ_COMPETITION_LIMITS,
  READ_COMPETITION_CLASSIFICATIONS_AGES,
  READ_COMPETITION_CLASSIFICATIONS_LIMITS,
  READ_COMPETITION_ORGANIZER
//   READ_UDAO_QUOTA,
} from "@/store/actions.type";
import countries from '@/assets/data/countries';
import prefixes from '@/assets/data/prefixes';
import Progress from '@/components/MinisteRegister/Progress';
import Classification from '@/components/MinisteRegister/Classification';
import SpecialCategory from '@/components/MinisteRegister/SpecialCategory';
import QuantityInput from '@/components/MinisteRegister/QuantityInput';
import PanelInputWrap from '@/components/PanelInputWrap';
// import { pick } from "@/utils/object";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, min, max, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'required_field'
})
extend('min', min);
extend('max', max);
extend('email', email);

export default {
  name: 'MinisiteRegister',
  components: {
    Progress,
    Classification,
	SpecialCategory,
	QuantityInput,
	PanelInputWrap,
	ValidationObserver,
	ValidationProvider
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.bootstrap();
      },
    },
    'form.user.city': {
      immediate: true,
      handler() {
        this.searchValueCity = this.form.user.city;
      },
    },
	'form.insurances': {
		handler(newVal, oldVal) {
			// Convert values to arrays if they're not
			const newVals = Array.isArray(newVal) ? newVal.map(Number) : [Number(newVal)];
			const oldVals = Array.isArray(oldVal) ? oldVal.map(Number) : oldVal ? [Number(oldVal)] : [];

			// Skip if both are empty
			if (!newVals.length && !oldVals.length) return;
			// Find added insurance
			const added = newVals.filter(id => !oldVals.includes(id))[0];
			if (added) {
				const insurance = this.insuranceProducts.find(i => i.id === added);
				if (insurance && insurance.packages[0].price) {
					this.form.totalPrice = (parseFloat(this.form.totalPrice) || 0) + parseFloat(insurance.packages[0].price);
					this.form.totalPrice = parseFloat(this.form.totalPrice).toFixed(2);
				}
			}

			// Find removed insurance 
			const removed = oldVals.filter(id => !newVals.includes(id))[0];
			if (removed) {
				const insurance = this.insuranceProducts.find(i => i.id === removed);
				if (insurance && insurance.packages[0].price) {
					this.form.totalPrice = (parseFloat(this.form.totalPrice) || 0) - parseFloat(insurance.packages[0].price);
					this.form.totalPrice = parseFloat(this.form.totalPrice).toFixed(2);
				}
			}
		}
	},
    birthDay: {
      handler() {
        this.form.price = null;
        this.form.classificationId = null;
        // if(this.birthDay && this.form.price !== null && this.form.price !== '0.00') {
        //   this.READ_UDAO_QUOTA({
        //     "person_insured_data": {
        //       "birth_date": this.birthDay
        //     },
        //     "event_date_start": this.competition?.startedTime.format('YYYY-MM-DD'),
        //     "event_date_end": this.competition?.startedTime.format('YYYY-MM-DD'),
        //     "event_price": this.form.price
        //   }).then((data) => this.insuranceProducts = data.data.products)        
        // }
      }
    },
	competition: {
		handler() {
			if (this.competition.frontFormType && this.competition.frontFormType === 'old') {
				const route = {
					name: 'minisite-register-old',
					params: this.competition,
					alias: true
				};
				this.$router.push(route);
			}
		}
	}
    // 'form.price': {
    //   handler() {
    //     if(this.birthDay && this.form.price !== null && this.form.price !== '0.00') {
    //       this.READ_UDAO_QUOTA({
    //         "person_insured_data": {
    //           "birth_date": this.birthDay
    //         },
    //         "event_date_start": this.competition?.startedTime.format('YYYY-MM-DD'),
    //         "event_date_end": this.competition?.startedTime.format('YYYY-MM-DD'),
    //         "event_price": this.form.price
    //       }).then((data) => this.insuranceProducts = data.data.products)        
    //     }
    //   }
    // }
  },  
  data() {
    return {
		isWaiting: false,
		basket: null,
		allFormSteps: [
			'playerData',
			'classification',
			'specialCategories',
			'inputs',
			'additionalInformation',
			'shop',
			'insurance',
			'summary',
			'status'
		],
		formSteps: [
			'playerData',
			'classification',
		],
		nextInCycleIds: [],
		currentFormStep: 'playerData',
		form: {
			totalPrice: null,
			price: null,
			insurances: [],
			classificationId: null,
			price: null,
			classifications: [],
			shopProducts: [],
			playerInputs: {},
			userId: null,
			user: {},
			inputs: [],
			city: '',
			regulations: {},
		},
		countries,
		prefixes,
		searchValueCity: '',
		errors: {},
		ready: false,
		insuranceProducts: [],
		shopProducts: [],
		regulations: [],
    };
  },
  created() {
      if (!this.user && !this.noAccount) {
        this.$router.push({ name: 'authentication-login', alias: true, query: {redirect: this.$route.path } });
      } else if(this.user) {
        this.form.userId = this.user.id
		this.form.user = this.user
      }
  },      
  computed: {
    ...mapGetters([
      'user',
      'say',
      'minisiteId',
      'competitions',
      'layoutsBy',
      'competitionsSettings',
      'classificationsBy',
      'classificationsSettings',
      'limitsBy',
	  'organizers',
    ]),
    competition() {
		return this.competitions[this.minisiteId];
    },
    layout() {
		return this.layoutsBy(this.competition)[0];
    },
    competitionSetting() {
		return this.competitionsSettings[this.minisiteId];
    },
    limits() {
		return this.limitsBy(this.competition)[0];
    },
	organizer() {
      return this.organizers[this.competition.organizer];
    },
    // classifications() {
    //   const classifications = this.classificationsBy(this.competition);

    //   for (const classification of classifications) {
    //     classification.position =
    //       this.classificationsSettings[
    //         classification.classificationSetting
    //       ].position;
    //   }
    //   return _.orderBy(classifications, "position", "asc");
    // },
    // nextInCycle() {
    //   return pick(this.competitions, this.nextInCycleIds);
    // },                
    noAccount() {
      return this.$route.query.skip ? true : false
    },
    birthDay() {
      return this.form.user && this.form.user.birthDate ? this.form.user.birthDate : null
    },
    birthAge() {
      return this.birthDay? Math.floor((new Date() - new Date(this.birthDay).getTime()) / 3.15576e+10) : null
    },
    // cityError: {
    //   get() {
    //       return (
    //           this.errors.city
    //           || this.errors.cityLat
    //           || this.errors.cityLng
    //       );
    //   },
    //   set() {
    //       this.errors.city = '';
    //       this.errors.cityLat = '';
    //       this.errors.cityLng = '';
    //   },
    // },
    styleObject() {
      return {
        '--color': this.layout.colourDefault,
      }
    },
	inputsSupportFormUser() {
      const list = this.form.inputs ? this.form.inputs.filter(input => input.fType === 'fc' && input.isPublic) : []

	  if (list.length > 0) {
		for (const item of list) {
			this.form.playerInputs[item.id] = {
			"value": null
			}
		}  
	}
      return list
    },
	inputsAdditionalInformation() {
      const list = this.form.inputs ? this.form.inputs.filter(input => input.fType !== 'fc') : []
	  if (list.length > 0) {
		for (const item of list) {
			this.form.playerInputs[item.id] = {
			"value": null
			}
		}  
	}
      return list
    },
	inputsConfirmationInformation() {
		const list = this.form.inputs ? this.form.inputs.filter(input => input.fType === 'fc') : []
		if (list.length > 0) {
		for (const item of list) {
			this.form.playerInputs[item.id] = {
			"value": null
			}
		}  
	}
      return list
	}
  },
  methods: {
    ...mapActions([
      READ_NEXT_IN_CYCLE_COMPETITIONS,
      READ_COMPETITION_CLASSIFICATIONS,
      BOOTSTRAP_MINISITE,
	  READ_COMPETITION_ORGANIZER,
      READ_COMPETITION_LIMITS,
      READ_COMPETITION_CLASSIFICATIONS_AGES,
      READ_COMPETITION_CLASSIFICATIONS_LIMITS,
    //   READ_UDAO_QUOTA,
    ]),
	async getBasket() {
		const basketId = this.basket?.basket_id

		try {
			const response = await api.get(`/basket/${basketId}/view`);
			console.log(response);
		} catch (e) {
			this.errors = e;
			return;
		}
	},
	async createBasket() {
		const payload = {};
		if(this.user) {
			payload.userId = this.user.id;
		} else {
			payload.sessionId = this.$store.state.common.accessToken;
		}
		try {
			const response = await api.post(`/basket/create`, payload);
			this.basket = response;
			await this.stepsCreator({
				formStep: '',
				competitionId: this.id,
			})
			// await this.getBasket()
		} catch (e) {
			this.errors = e;
			return;
		}
	},
	async saveOrder() {
		const basketId = this.basket?.basket_id
		try {
			await api.post(`/basket/${basketId}/save`);
			this.currentFormStep = 'status';
		} catch (e) {
			this.errors = e;
			return;
		}
	},
	async stepsCreator(payload) {
		const basketId = this.basket?.basket_id

		try {
			const response = await api.post(`/basket/${basketId}/steps`, payload);
			// console.log(response)
			 if (response) {
				this.currentFormStep = response.formStep;
				
				if (!this.formSteps.includes(response.formStep)) {
					this.formSteps.push(response.formStep);
				}

				if (response.data?.playerData) this.form.user = response.data.playerData
				if (response.data?.classifications) {
					this.form.classification = null
					this.form.classifications = response.data.classifications
				}
				if (response.data?.specialCategories) {
					this.form.specialCategories = response.data.specialCategories
				}
				if (response.data?.inputs) this.form.inputs = response.data.inputs
				if (response.formStep === 'insurance' && response.data?.insurance) {
					this.insuranceProducts = response.data.insurance.products
				}
				if (response.formStep === 'shop' && response.data?.shop) {
					this.shopProducts = response.data.shop
				}
				if (response.data?.items) this.form.items = response.data.items
				if (response.price) this.form.totalPrice = response.price
				if (response.data?.totalPrice) this.form.totalPrice = response.data.totalPrice
				if (response.data?.regulations) this.regulations = response.data.regulations
			 }
		} catch (e) {
			this.errors = e;
			return;
		}
	},
    async bootstrap() {
      this.ready = false;

	  const [{ competitions: nextInCycleIds }] = await Promise.all([
        this.READ_NEXT_IN_CYCLE_COMPETITIONS(this.id),
		this.createBasket(),
        this.READ_COMPETITION_CLASSIFICATIONS(this.id),
        this.BOOTSTRAP_MINISITE(this.id),
        this.READ_COMPETITION_LIMITS(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS_AGES(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
		this.READ_COMPETITION_ORGANIZER(this.id),
      ]);
      this.nextInCycleIds = nextInCycleIds;
      this.ready = true;
    },
    async nextFormStep() {
      const currentIdx = this.formSteps.findIndex(i => i === this.currentFormStep)
	  this.isWaiting = true;
	  const payload = {
			formStep: this.formSteps[currentIdx],
			competitionId: this.id,
			classificationId: this.form.classificationId,
			...this.form.user,
		}
	  const playerInputs = this.form.playerInputs
	  if (payload.birthDate) {
		payload.birthDate = new Date(payload.birthDate).toISOString().split('T')[0].replace(/-/g, '.');
	  }
	  if (playerInputs && Object.keys(playerInputs).length > 0) {
		payload.playerInputs = []
		for (let input in playerInputs) {
			if(playerInputs[input].value !== '' || playerInputs[input].value !== null) {
			let item = {
				"input": parseFloat(input),
				"value":  playerInputs[input].value
			}
			if(playerInputs[input].id) {
				item.id = playerInputs[input].id
			}
				payload.playerInputs.push(item)
			}
		}
	  }
	  if (this.form.insurances && this.form.insurances.length > 0) {
		payload.insurances = this.form.insurances
	  }
	  if (this.form.shopProducts && this.form.shopProducts.length > 0) {
		payload.shopProducts = this.form.shopProducts.filter(product => product.quantity > 0)
	  }
	  await this.stepsCreator(payload);
	  this.isWaiting = false;
    //   this.currentFormStep = this.formSteps[currentIdx + 1]
    }, 
    prevFormStep() {
      const currentIdx = this.formSteps.findIndex(i => i === this.currentFormStep)
      this.currentFormStep = this.formSteps[currentIdx - 1]
    }, 
    selectCity(value) {
      // place changed event
      if (value) {
          this.$set(this.form.user, 'cityLng', value.cityLng);
          this.$set(this.form.user, 'cityLat', value.cityLat);
          this.$set(this.form.user, 'city', value.text);
          setTimeout(() => {
              this.searchValueCity = value.text;
          }, 40);
      }
    }, 
	handleQuantityChange(quantity) {
		// console.log('Quantity changed:', quantity);
		const existingProductIndex = this.form.shopProducts.findIndex(
			(item) => item.id_product === quantity.id_product && item.model === quantity.model
		);
		const product = this.shopProducts.find(
			(item) => item.id === quantity.id_product && item.models.some((model) => model.id === quantity.model)
		);

		if (!product) {
			console.error('Product not found for quantity change');
			return;
		}

		const existingProduct = this.form.shopProducts[existingProductIndex];
		const previousQuantity = existingProduct ? existingProduct.quantity : 0;
		const priceDifference = parseFloat(product.price) * (quantity.quantity - previousQuantity);

		if (existingProductIndex !== -1) {
			this.form.shopProducts.splice(existingProductIndex, 1, quantity);
		} else {
			this.form.shopProducts.push(quantity);
		}

		this.form.totalPrice = (parseFloat(this.form.totalPrice) || 0) + priceDifference;
		this.form.totalPrice = parseFloat(this.form.totalPrice).toFixed(2);
	},
	getShopProductValue(productId, modelId = null) {
		// console.log(productId, modelId)
		const product = this.form.shopProducts.find(
			(item) => item.id_product === productId && item.model === modelId
		);
		return product ? product.quantity : 0;
	},
	async registerSubmit() {
		this.$refs.registerFormObserver.validate().then(success => {
			if (!success) {
				for (const key of Object.keys(this.$refs.registerFormObserver.fields)) {
					if (this.$refs.registerFormObserver.fields[key].invalid) {
						const y = this.$refs.registerFormObserver.refs[key].$el.getBoundingClientRect().top;
						this.$refs.registerFormObserver.refs[key].$el.scrollIntoView({
							top: y,
							behavior: 'smooth',
						});
						return;
					}
				}
			}
			if (this.currentFormStep === 'summary') {
				this.saveOrder();
			} else {
				this.nextFormStep();
			}
		});
	},
  },
}
</script>

<style scoped>
.tc1, .tc1:hover, .tc1:active, .tc1:visited, .tc1:focus{ color: var(--color); }
.tc1bg{ background-color: var(--color); color:#fff;background-image: none;box-shadow: none;}
.tc1bo{ background-color:transparent; border-color: var(--color); color: var(--color); }
.tc1hr{ background-color:var(--color); border-color: var(--color); color: var(--color); }
.tc2, .tc2:hover, .tc2:active, .tc2:visited, .tc2:focus{ color: var(--color); }
.tc2bg{ background-color: var(--color); color:#fff; background-image: none;box-shadow: none;}
.tc2bo{ background-color:transparent; border-color: var(--color); color: var(--color); }
.tc2bo:hover{ background-color:transparent !important; }
.tc2hr{ background-color:var(--color); border-color:var(--color); color:var(--color); }
.register-header {margin-top: 40px;margin-bottom: 40px;display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;}
.register-header-img {margin-top: 48px}
.register-header-img img {max-width: 150px;height: auto;}
.register-header.align-start {align-items: flex-start;}
.register-header.align-start >>> .progress {margin-top: 15px;}
.section-wrap {border-radius: 4px;padding: 32px 98px;}
.register-step-section .row, .section-wrap .row {margin-left: -15px;margin-right: -15px;}
.section-wrap .row + .row {margin-top: 24px;}
.register-step-section .row:not(.p4) > [class^="col-"], .section-wrap .row:not(.p4) > [class^="col-"], .register-step-section .row:not(.p4) > [class*=" col-"], .section-wrap .row:not(.p4) > [class*=" col-"] {padding-left: 15px;padding-right: 15px;box-sizing: border-box;}
.register-step-section .row.p4, .section-wrap .row.p4 {margin-left: -4px;margin-right: -4px;}
.register-step-section .row.p4 > [class^="col-"], .section-wrap .row.p4 > [class^="col-"], .register-step-section .row.p4 > [class*=" col-"], .section-wrap .row.p4 > [class*=" col-"] {padding-left: 4px;padding-right: 4px;box-sizing: border-box;}
.register-step-section + .register-step-section {margin-top: 24px;}
.register-step-section > .row + .row {margin-top: 16px;}
.register-step-buttons {margin-top: 48px;}
.section-content + .section-content {margin-top: 24px;}
.section-content-wrap {margin-bottom: 32px;}
.section-content p {font-weight: 400; font-size: 14px; line-height: 20px; color: var(--color-greyish-brown);}
.section-content b {font-weight: 600; font-size: 16px; line-height: 24px; color: var(--color-greyish-brown);}
.section-wrap .hint {font-weight: 400;font-size: 14px;line-height: 24px;color: var(--color-greyish-brown);}
.register-summary-info {background: var(--color-pale-grey);border-radius: 4px;padding: 16px 24px 16px 16px; display: flex; flex-direction: column; gap: 8px;}
.register-summary-info > div {display: flex; flex-direction: column; gap: 4px;}
.register-summary-info + .register-summary-info {margin-top: 16px}
.register-summary-info__cart {padding: 8px;}
.register-summary-cart-item {display: flex;align-items: center;padding: 12px 16px 12px 12px;}
.register-summary-cart-item + .register-summary-cart-item {margin-top: 8px;}
.register-summary-cart-details b {display: block;}
.register-summary-cart-img {width: 64px;height: 64px;background: #fff;border-radius: 4px;overflow: hidden;margin-right: 16px;}
.register-summary-cart-img img {max-width: 100%;height: 100%;object-fit: cover;}
.register-summary-cart-price {margin-left: auto}
.register-summary-cart-price span {font-family: 'Open Sans';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;margin-right: 16px;}
.register-summary-info__coupon {margin-top: 16px;}
.register-summary-info__coupon-form {display: flex;}
.register-summary-info__coupon-form > * {width: 100%;}
.register-summary-info__coupon-form .btn {margin-left: 8px;}
.text-success {color: var(--color-dark-pastel-green)}
.text-error {color: var(--color-pinky-red)}
.text-normal {font-family: 'Open Sans', sans-serif;}
.register-cycle-info {background: var(--color-pale-grey);padding: 24px 26px 44px 26px;}
.register-cycle-info p {font-size: 16px;line-height: 24px;margin-bottom: 16px;}
.register-cycle-wrap {display: flex;}
.register-cycle-item {padding: 12px 16px;}
.register-cycle-item:not(:last-of-type) {margin-right: 8px;}
.register-cycle-item p {font-size: 14px;line-height: 24px;margin-bottom: 0;font-weight: 600;}
.register-cycle-item b {font-family: 'Bariol Bold';font-size: 24px;line-height: 32px;}
.register-step-header p {margin-top: 8px;}
.register-shop-items {display: grid;gap: 16px;}
.register-shop-item {background: var(--color-pale-grey); border-radius: 4px;height: 100%;}
.register-shop-img {background-color: #fff;border-radius: 4px;text-align: center;margin: 16px 16px 34px 16px;height: 200px;}
.register-shop-img img {max-height: 200px;max-width: 100%;}
.register-shop-details {display: flex;align-items: center;justify-content: space-between;margin-bottom: 34px;padding: 16px 15px;}
.register-shop-price {font-family: 'Bariol Bold';font-size: 18px;line-height: 24px;flex-shrink: 0;}
.register-shop-variants {padding: 16px 15px;}
.register-shop-variants-item {display: flex;align-items: center;justify-content: space-between;}
.register-shop-variants-item + .register-shop-variants-item {margin-top: 4px;}
.register-shop-description {padding: 16px 15px;}
.section-wrap-form {margin-top: 32px;} 
.section-wrap-form p {margin-bottom: 16px;} 
.section-wrap-form >>> .input-field + .input-field, .section-wrap-form >>> .input-field + .row {margin-top: 24px;}
.section-content + .btn {margin-top: 32px}
.register-status {display: flex;justify-content: center;flex-wrap: wrap;}
.register-status-image {width: 267px;margin-right: 30px;text-align: center;}
.register-status-content {width: 360px;}
.register-status-content h3 {margin-bottom: 16px;}
.register-status-content p {font-size: 14px;line-height: 24px;}
.register-status-content p + p {margin-top: 16px;}
.register-status-content b {display: block;font-family: 'Bariol Bold';font-size: 24px;line-height: 32px;}
.register-status-content .btn {margin-top: 32px;margin-bottom: 32px;}
.scroll-margin::v-deep .panel-input-error.input-error {left: 0;  bottom: 0;  padding: 2px 4px;  position: relative;}
.user-summary {display: flex;flex-direction: column;gap: 16px;}
.user-summary > div {display: flex; flex-direction: column; gap: 4px;}
@media (max-width: 768px) {
  .section-wrap-form >>> .input-field + .row > .col-12 + .col-12 {margin-top: 24px;}
}
@media (max-width: 992px) {
  .register-header {margin-top: 16px;margin-bottom: 36px;}
  .register-header h3 {font-size: 18px;}
  .progress {margin-top: 16px;width: 100%;}
  .register-step-header {margin-bottom: 16px;}
  .register-step-header h4 {margin-bottom: 8px;}
  .section-wrap {padding: 24px 15px;}
  .section-wrap .hint {margin-top: 12px;}
  .register-step-buttons {margin-top: 32px;}
  .register-step-buttons .order-2 {order: 2;}
  .register-step-buttons .order-1 {order: 1;margin-bottom: 16px;}
  .register-cycle-info {padding: 16px 15px;}
  .register-cycle-wrap {flex-wrap: wrap;}
  .register-cycle-item {padding: 12px 15px;display: flex;align-items: center;justify-content: space-between; width: 100%;}
  .register-cycle-item b {font-size: 18px;line-height: 24px;}
  .register-cycle-item:not(:last-of-type) {margin-right: 0}
  .register-status-image {width: 100%;margin-right: 0;}
  .register-status-content {width: 100%;}
  .register-step.status {
		margin-top: 24px;
	}
}
@media (min-width: 992px) {
	.register-shop-items {grid-template-columns: repeat(3, 1fr);}
	.register-summary-info > div {flex-direction: row; align-items: center; gap: 16px;}
	.register-summary-info h4 {flex-shrink: 0;margin-left: auto;}
	.register-step.status {
		margin-top: 64px;
	}
}
</style> 