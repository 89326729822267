<template>
	<div class="register-shop-variants__quantity">
	  <div 
		class="jsQty jsQtyMinus" 
		:class="{ 'jsQty-disabled': quantity <= 0 }"
		@click="decrement"
	  >
		<i class="fa fa-minus"></i>
	  </div>
	  <input 
		type="text" 
		:name="`shop_assortment[${product.id}][${model?.id || 0}]`" 
		class="jsShopAssortment" 
		v-model="quantity"
		@input="emitQuantity"
	  />
	  <div class="jsQty jsQtyPlus" @click="increment">
		<i class="fa fa-plus"></i>
	  </div>
	</div>
  </template>
  
  <script>
  export default {
	props: {
	  product: {
		type: Object,
		required: true,
	  },
	  model: {
		type: Object,
		default: () => {},
	  },
	  value: {
		type: Number,
		default: 0,
	  }
	},
	data() {
	  return {
		quantity: 0,
	  };
	},
	created() {
	  this.quantity = this.value;
	},
	watch: {
	  value(newValue) {
		this.quantity = newValue;
	  },
	},
	methods: {
	  increment() {
		this.quantity += 1;
		this.emitQuantity();
	  },
	  decrement() {
		if (this.quantity > 0) {
		  this.quantity -= 1;
		  this.emitQuantity();
		}
	  },
	  emitQuantity() {
		this.$emit('quantity-change', {
			id_product: this.product.id,
			model: this.model && Object.keys(this.model).length > 0 ? this.model.id : null,
			quantity: this.quantity,
	  	});
		},
	},
  };
  </script>

<style scoped>
.register-shop-variants__quantity {display: flex;align-items: center;justify-content: space-between;}
.jsShopAssortment {border: none;background: transparent;width: 32px;text-align: center;font-family: 'Bariol Bold';font-size: 18px;pointer-events: none;}
.jsQty {cursor: pointer;background: var(--color-slate-two);box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);border-radius: 4px;width: 48px;height: 48px;display: flex;align-items: center;justify-content: center;}
.jsQty i {width: 32px;height: 32px;background: var(--color-greyish-brown);color: #fff;font-size: 22px;display: flex;align-items: center;justify-content: center;}
.jsQty-disabled {background: transparent;border: 1px solid var(--color-slate-two);cursor: not-allowed;box-shadow: none;}
.jsQty-disabled i {display: none;}
</style>