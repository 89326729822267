<template>
    <div class="textarea-field" :class="{ 'textarea-field--error': error }">
        <p
            v-if="label && label.length"
            class="mab8"
        >
            {{ label
            }}<span
                v-if="optional"
                class="positive"
            >(opcjonalnie)</span>
        </p>      

        <textarea
          class="input-field__textarea"
          :rows="rows"
          :value="value"
          @input="$emit('input', $event.target.value)"
        />

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        rows: {
            type: Number,
            required: true,
        },
        hint: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },        
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.textarea-field {
	position: relative;
}
.textarea-field .input-error {
	bottom: -15px;
}
.textarea-field--error .input-field__textarea {
	border: solid 2px var(--color-pinky-red) !important
}
.input-field__textarea {
    padding: 10px;
    outline-color: var(--color-dark-pastel-green);
    box-shadow: 0px 8px 12px rgb(0 0 0 / 10%);
}
</style>