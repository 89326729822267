<template>
  <div>
    <panel-input-text
      v-if="data.fType === 'fi'"
      :label="data.fNamePl"
      :value="$attrs.value"
      class="mab24"
      @input="$emit('input', $event)"
	  :error.sync="localErrors"
    />
    <panel-input-area
      v-else-if="data.fType === 'fl'"
      :label="data.fNamePl"
      :value="$attrs.value"
      :rows="2"
      class="mab24"
      @input="$emit('input', $event)"
	  :error.sync="localErrors"
    />
    <panel-input-radio
      v-else-if="data.fType === 'fr'"
      :key="`${data.fType}_${data.id}_key`"
      :name="`${data.fType}_${data.id}`"
      :label="data.fNamePl"
      :value="$attrs.value"
      :options="radioOptions"
      @input="radioOnInput"
      class="mab24"
	  :error.sync="localErrors"
	  :wrap="options?.fr?.wrap"
    />
    <panel-input-select
      v-else-if="data.fType === 'fs'"
      :select-label="data.fNamePl"
      label=""
      track-by=""
      :options="data.fDescriptionPl"
      :hide-selected="false"
      @input="$emit('input', $event)"
      :value="$attrs.value"
      class="mab24"
	  :error.sync="localErrors"
    /> 
    <panel-input-text
      v-else-if="data.fType === 'fj'"
      :label="data.fNamePl"
      :hint="data.fDescriptionPl"
      :value="$attrs.value"
      class="mab24"
      @input="$emit('input', $event)"
	  :error.sync="localErrors"
    />    
    <panel-input-date
      v-else-if="data.fType === 'fh'"
      :label="data.fNamePl"
      mode="time"
      :model-config="{
        type: 'string',
        mask: 'hh.mm', 
      }"
      placeholder="hh.mm"
      class="mab24"
      @input="$emit('input', $event)"
      :value="$attrs.value"
	  :error.sync="localErrors"
    />    
    <panel-input-date
      v-else-if="data.fType === 'fd'"
      :label="data.fNamePl"
      placeholder="dd.mm.yyyy"
      class="mab24"
      @input="$emit('input', $event)"
      :value="$attrs.value"
	  :error.sync="localErrors"
    />   
    <panel-input-checkbox 
      v-else-if="data.fType === 'fc'"
      :checkbox-value="{ value: data.id, text: data.fNamePl || data.fDescriptionPl }" 
      :name="`input_ids_add[${data.id}]`"
      @input="$emit('input', $event)"
      class="mab24"
      :value="$attrs.value"
	  :error.sync="localErrors"
    >
      {{ data.fNamePl || data.fDescriptionPl }}
    </panel-input-checkbox>

  </div>
</template>

<script>
import PanelInputText from '@/components/PanelInputText';
import PanelInputArea from '@/components/PanelInputArea';
import PanelInputRadio from '@/components/PanelInputRadio';
import PanelInputSelect from '@/components/PanelInputSelect';
import PanelInputDate from '@/components/PanelInputDate';
import PanelInputCheckbox from '@/components/PanelInputCheckbox';

export default {
  name: 'PanelInputWrap',
  props: {
    data: {
      type: Object,
      required: true,
    },
	errors: {
		type: String,
		default: '',
	},
	options: {
		type: Object,
		default: () => {},
	}
  },
  components: {
    PanelInputText,
    PanelInputArea,
    PanelInputRadio,
    PanelInputSelect,
    PanelInputDate,
    PanelInputCheckbox,
  },
  computed: {
	localErrors: {
		get(){
			return this.errors
		},
		set(newError){
			return newError
		} 
	},
    radioOptions() {
      const arr = []
      if (this.$props.data.fDescriptionPl && this.$props.data.fDescriptionPl.length) {
        for (const desc of this.$props.data.fDescriptionPl) {
          arr.push({ value: desc, title: desc })
        }
      } else {
        arr.push({ value: true, title: 'Tak' })
        arr.push({ value: '0', title: 'Nie' })
      }
      return arr
    }
  },
  methods: {
    radioOnInput($event) {
      if (this.$props.data.fDescriptionPl && this.$props.data.fDescriptionPl.length) {
        this.$emit('input', $event)
      } else {
        this.$emit('input', $event === 'true' ? true : '0')
      }
    }
  }
}
</script>
