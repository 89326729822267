<template>
	<div class="register-classification" :class="{ 'disabledCl': !inAgeRange || !classification.posible, 'error': error }">
	  <div class="register-classification__header">
		<div class="register-classification__name">{{ classification.name }}</div>
		<template v-if="(classificationSetting && classificationSetting.isPay) && (prices && !prices.length)">
		  <div class="register-classification__price">{{ say.unknown_payment_information }}</div>
		</template>
		<template v-else-if="classificationSetting && !classificationSetting.isPay">
		  <div class="register-classification__price">{{ say.feeless }}</div>
		</template>
		<template v-else-if="ascSortPrices && ascSortPrices.length">
		  <div class="register-classification__price">{{ ascSortPrices[0].price }} {{ currency }}</div>
		</template>        
	  </div>
	  <div class="register-classification__details">
		<div>
		  <div>
			<p>{{ say.distance }}</p>
			<b>{{ classification.distance }} km</b>
		  </div>
		  <div>
			<p>{{ say.gender }}</p>
			<b>
			  <template v-if="canM && canK">
				{{ say.woman_short }},{{ say.man_short }}
			  </template>
			  <template v-else-if="canM">
				{{ say.man_short }}
			  </template>
			  <template v-else-if="canK">
				{{ say.woman_short }}
			  </template>            
			</b>
		  </div>
		  <div>
			<p>{{ say.age }}</p>
			<b>{{ age.from }}-{{ age.to }}</b>
		  </div>
		  <template  v-if="canRegisterClassification(competition, limits)">
		  <div>
			<p>{{ say.available }}</p>
			<b>{{ limits.playersAvailable }} / {{ limits.playersLimit }}</b>
		  </div>
		  </template>
		  <template v-else>
		  <div>
			<p>{{ say.number_of_participants }}</p>
			<b>{{ limits.playersUnavailable }}</b>
		  </div> 
		  </template>
		</div>
		<div>
		  <div class="btnchk">
			<label @change="$emit('input', { value: $event.target.value, price: prices && Object.keys(prices).length > 0 ? prices.price : 0 })" :for="`classifications[${competitionId}][${id}]`">
			  <input type="radio" :value="id" :id="`classifications[${competitionId}][${id}]`" :name="`classification[${competitionId}]`" :checked="checked" />
			  <div class="btnchk-wrap">
				<span class="btnchk-txt">Wybierz klasyfikację</span>
				<span class="btnchk-ico"></span>
			  </div>
			</label>
		  </div>                    
		</div>
	  </div>
	  <template v-if="(classificationSetting && classificationSetting.isPay) && (prices && Object.keys(prices).length === 0)">
		<div class="register-classification__details">
			<div class="register-classification__price" v-html="say.unknown_payment_information" />
		</div>
		</template>
		<template v-else-if="classificationSetting && !classificationSetting.isPay">
			<div class="register-classification__details">
				<div class="register-classification__price" v-html="say.feeless" />
			</div>
		</template>
		<template v-else-if="(prices && Object.keys(prices).length > 0)">
			<div class="register-classification__details">
				<div class="register-classification__price">{{ prices.price }} {{ currency }}</div>
			</div>
	</template>
	<div v-if="classification && classification.error_info" class="validation-text">{{ classification.error_info }}</div>
	</div>  
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import actions from '@/mixins/actions';
  import _ from 'lodash';
  
  export default {
	name: 'MinisiteRegisterClassification',
	mixins: [actions],
	props: {
	  id: {
		type: Number,
		required: true,
	  },
	  classification: {
		type: Object,
		required: true,
	  },
	  competitionId: {
		type: Number,
		required: true,
	  },
	  playerAge: {
		type: Number,
		required: false,
		default: 0,
	  },
	  checked: {
		type: Boolean,
		default: false,
	  },
	  error: {
		type: String,
		default: '',
	  }
	},
	computed: {
	  ...mapGetters([
		'say',
		'classifications',
		'classificationsSettings',
		'competitions',
		'competitionsCurrency',
	  ]),
	  currency() {
		if (this.competitionsCurrency[this.competition.id]) {
		  return this.competitionsCurrency[this.competition.id].currencySymbol;
		}
		return 'zł';
	  },
	  classificationSetting() {
		return this.classificationsSettings[this.id];
	  },
	  competition() {
		return this.competitions[this.competitionId];
	  },
	  limits() {
		return this.classification.limits;
	  },
	  ages() {
		return this.classification.ages;
	  },
	  prices() {
		return this.classification.price;
	  },
	  ascSortPrices() {
		if (this.classificationSetting.pricesControlledBy === 'date') {
		  return _.sortBy(this.prices, (price) => price.endedTime);
		}
		return _.sortBy(this.prices, (price) => Number.parseFloat(price.price));
	  },
	  canM() {
		return !!this.ages.m;
	  },
	  canK() {
		return !!this.ages.k;
	  },
	  ageM() {
		return {
		  from: this.ages.m.from || 0,
		  to: this.ages.m.to || 99,
		};
	  },
	  ageK() {
		return {
		  from: this.ages.k.from || 0,
		  to: this.ages.k.to || 99,
		};
	  },
	  age() {
		const froms = [];
		const tos = [];
  
		if (this.canM) {
		  froms.push(this.ageM.from);
		  tos.push(this.ageM.to);
		}
  
		if (this.canK) {
		  froms.push(this.ageK.from);
		  tos.push(this.ageK.to);
		}
  
		return {
		  from: Math.min(...froms),
		  to: Math.max(...tos),
		};
	  },
	  inAgeRange() {
		return this.playerAge >= this.age.from && this.playerAge <= this.age.to
	  }
	},   
  }
  </script>
  
  <style scoped>
  .btnchk input {display: none;}
  .btnchk-wrap {cursor:pointer;user-select: none;width: 247px;height: 48px;padding-left: 16px;padding-right: 8px;box-sizing: border-box;display: flex;align-items: center;justify-content: space-between;background: #546E7A;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);border-radius: 4px;font-size: 14px;line-height: 20px;color: #fff;}
  .btnchk-ico {width: 32px;height: 32px;background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.1875 8.375C22.6562 8.375 23.0547 8.53906 23.3828 8.86719C23.7109 9.19531 23.875 9.59375 23.875 10.0625V22.4375C23.875 22.9062 23.7109 23.3047 23.3828 23.6328C23.0547 23.9609 22.6562 24.125 22.1875 24.125H9.8125C9.34375 24.125 8.94531 23.9609 8.61719 23.6328C8.28906 23.3047 8.125 22.9062 8.125 22.4375V10.0625C8.125 9.59375 8.28906 9.19531 8.61719 8.86719C8.94531 8.53906 9.34375 8.375 9.8125 8.375H22.1875ZM21.9766 22.4375C22.1172 22.4375 22.1875 22.3672 22.1875 22.2266V10.2734C22.1875 10.1328 22.1172 10.0625 21.9766 10.0625H10.0234C9.88281 10.0625 9.8125 10.1328 9.8125 10.2734V22.2266C9.8125 22.3672 9.88281 22.4375 10.0234 22.4375H21.9766Z' fill='white'/%3E%3C/svg%3E%0A");}
  .btnchk input:checked + .btnchk-wrap {background-color: var(--color-dark-pastel-green)}
  .btnchk input:checked + .btnchk-wrap .btnchk-ico {background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.1875 24.125H9.8125C9.34375 24.125 8.94531 23.9609 8.61719 23.6328C8.28906 23.3047 8.125 22.9062 8.125 22.4375V10.0625C8.125 9.59375 8.28906 9.19531 8.61719 8.86719C8.94531 8.53906 9.34375 8.375 9.8125 8.375H22.1875C22.6562 8.375 23.0547 8.53906 23.3828 8.86719C23.7109 9.19531 23.875 9.59375 23.875 10.0625V22.4375C23.875 22.9062 23.7109 23.3047 23.3828 23.6328C23.0547 23.9609 22.6562 24.125 22.1875 24.125ZM14.9805 20.6797L21.4492 14.2109C21.7305 13.9531 21.7305 13.6836 21.4492 13.4023L20.6758 12.6289C20.3945 12.3477 20.125 12.3477 19.8672 12.6289L14.5938 17.9023L12.1328 15.4414C11.875 15.1602 11.6055 15.1602 11.3242 15.4414L10.5508 16.2148C10.2695 16.4961 10.2695 16.7656 10.5508 17.0234L14.207 20.6797C14.4648 20.9375 14.7227 20.9375 14.9805 20.6797Z' fill='white'/%3E%3C/svg%3E%0A");}
  
  .register-classification {background: var(--color-pale-grey);border-radius: 4px;padding: 16px 16px 20px 24px;border: 2px solid transparent;}
  .register-classification__header, .register-summary-info__header {display: flex;align-items: center;justify-content: space-between;}
  .register-classification__header .register-classification__price {margin-right: 0;display: none;}
  .register-classification__name, .register-classification__price, .register-summary-info__name, .register-summary-info__price, .register-summary-cart-price {font-family: 'Bariol Bold';font-size: 18px;line-height: 24px;color: var(--color-greyish-brown);}
  .register-classification.category .register-classification__name {font-size: 24px;line-height: 26px;margin-bottom: 0;}
  .register-classification__name {margin-bottom: 20px;}
  .register-classification__price {margin-right: 29px;}
  .register-classification__details, .register-classification__details > div {display: flex;align-items: center;justify-content: space-between;}
  .register-classification__details > div:first-child div:not(:last-child) {margin-right: 46px;}
  .register-classification__details > div:first-child div:last-child {margin-left: 20px;}
  .register-classification__details > div:first-child p {font-size: 14px;line-height: 16px;}
  .register-classification__details > div:first-child b {font-size: 16px;line-height: 24px;}
  .register-classification + .register-classification {margin-top: 8px;}
  .register-classification.disabledCl > div:not(.validation-text) {opacity: 0.5;pointer-events: none;}
  .register-classification.disabledCl .btnchk-wrap {border: 1px solid var(--color-greyish-brown);background: transparent;box-shadow: none;color:var(--color-greyish-brown); }
  .register-classification.disabledCl .btnchk-ico {background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.1875 8.375C22.6562 8.375 23.0547 8.53906 23.3828 8.86719C23.7109 9.19531 23.875 9.59375 23.875 10.0625V22.4375C23.875 22.9062 23.7109 23.3047 23.3828 23.6328C23.0547 23.9609 22.6562 24.125 22.1875 24.125H9.8125C9.34375 24.125 8.94531 23.9609 8.61719 23.6328C8.28906 23.3047 8.125 22.9062 8.125 22.4375V10.0625C8.125 9.59375 8.28906 9.19531 8.61719 8.86719C8.94531 8.53906 9.34375 8.375 9.8125 8.375H22.1875ZM21.9766 22.4375C22.1172 22.4375 22.1875 22.3672 22.1875 22.2266V10.2734C22.1875 10.1328 22.1172 10.0625 21.9766 10.0625H10.0234C9.88281 10.0625 9.8125 10.1328 9.8125 10.2734V22.2266C9.8125 22.3672 9.88281 22.4375 10.0234 22.4375H21.9766Z' fill='%234a4a4a'/%3E%3C/svg%3E%0A");}
  .error.register-classification {border: 2px solid var(--color-pinky-red);}
  .register-classification__details + .register-classification__details { margin-top: 16px; }
  @media (max-width: 992px) {
	.register-classification {padding: 16px;}
	.register-classification__header {border-bottom: 1px solid rgba(0,0,0,0.1);padding-bottom: 16px;margin-bottom: 16px;}
	.register-classification__header .register-classification__name {margin-bottom: 0;}
	.register-classification__header .register-classification__price {display: block;}
	.register-classification__details .register-classification__price {display: none;}
	.register-classification__details {flex-wrap: wrap;}
	.register-classification__details > div {width: 100%;}
	.register-classification__details > div:nth-child(2) {width: 100%;margin-top: 16px;}
	.register-classification__details .btnchk, .register-classification__details .btnchk-wrap {width: 100%;}
	.register-classification__details > div:first-child div:not(:last-child) {margin-right: 16px;}
	.register-classification__details > div:first-child div:last-child {margin-left: auto;text-align: right;}
  }
  </style>
  