<template>
    <div>
        <!-- Register button. -->
        <base-button
            v-if="canRegister(competition, limits)"
            btn1
            @click="handleSignUp"
        >
            {{ say.sign_up }}
        </base-button>

        <!-- Results button. -->
        <base-button
            v-else-if="competition.status >= 4"
            btn1
            @click="
                $router.push({
                    name: 'minisite-results-pick-classification',
                    params: competition,
                    alias: true,
                })
            "
        >
            {{ competition.status === 4 ? say.statuses_4 : say.results }}
        </base-button>

        <!-- Register outside button. -->
        <base-button-info
            v-else-if="canRegisterOutside(competition)"
            @click="$router.push({ path: competition.websitePl, tab: true })"
        >
            {{ say.sign_up }}
            <template v-slot:info>
                {{ say.registrations_off_platform }}
            </template>
        </base-button-info>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import actions from '@/mixins/actions';

export default {
    mixins: [actions],
    computed: {
        ...mapGetters(['user', 'say', 'competitions', 'limitsBy', 'minisiteId']),
        competition() {
            return this.competitions[this.minisiteId];
        },
        limits() {
            return this.limitsBy(this.competition)[0];
        },
    },
    methods: {
        handleSignUp() {
            const entrySignUp = {
                event: 'zapisany',
                event_category: 'zapiszsie',
            };
			const route = {
				name: this.competition.frontFormType === 'new' ? 'minisite-register' : 'minisite-register-old' || 'minisite-register-old',
				params: this.competition,
				alias: true
			};
			// const fullPath = this.$router.resolve(route).href;
			const rawPath = this.$router.resolve(route).route.fullPath;

			this.$gtm.push(entrySignUp);
			if (this.user) {
				this.$router.push(route);
			} else {
				this.$router.push({
					name: 'authentication-login',
					alias: true,
					query: { redirect: rawPath },
					params: { id: this.competition.id },
				});
			}
        },
    },
};
</script>
