<template>
    <div 
      role="group" :aria-labelledby="name"
      class="panel-radio-field"
      @change="$emit('input', $event.target.value)"
    >
        <p :id="name" class="mab8">{{ label }}</p>
        <div class="panel-radio-field__wrap" :class="{ 'input-field--error': error }">
          <template v-for="option of options">
              <div class="panel-radio-wrapper" :class="{ 'w100': wrap }">
                <input
                    :id="`${name}_${option.value}`"
                    :key="`${name}_${option.value}`"
                    v-model="innerValue"
                    type="radio"
                    :value="option.value"
                    :name="name"
                >
                <label
                    :for="`${name}_${option.value}`"
                >{{ say[option.title] || option.title }}</label>
              </div>
          </template>
        </div>

        <!-- <div class="panel-radio-field__wrap">
          <template v-for="option of options">
              <input
                  :id="option.value"
                  :key="option.value"
                  v-model="picked"
                  type="radio"
                  class="d-none"
                  :value="option.value"
              >
              <label
                  :key="option.title"
                  class="panel-radio-field__label"
                  :for="option.value"
              >{{ say[option.title] || option.title }}</label>
          </template>
        </div> -->

        <span
            class="panel-input-error input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        options: {
            // [ { value: String, title: String }, ... ]
            type: Array,
            required: true,
        },
        name: {
          type: String,
          required: true,
        },
        value: {
            type: null,
            default: '',
        },
		wrap: {
			type: Boolean,
			default: false,
		},
    },
    data () {
        return {
            innerValue: null,
        }
    },
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        },
    },
    created() {
        if (this.value !== null || this.value !== undefined ) {
            this.innerValue = this.value === "1" ? true : this.value
        }
    },    
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.panel-radio-field {
    border: 0;
    padding: 0;
    margin: 0;
}
.panel-radio-field.mab24 {
    margin-bottom: 24px;
}
.panel-radio-field__wrap {
    padding: 8px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    background: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: solid 2px transparent;
}
.panel-radio-wrapper {
    margin-left: 20px;
}
.panel-radio-wrapper.w100 {
	width: 100%;
}
input[type=radio] {
    opacity: 0;
}
input[type=radio] + label {    
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    color: var(--color-slate);
    line-height: 1.3;
}
input[type=radio] + label::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -26px;
    border-radius: 50%;
    border: 1px solid #455A64;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
}
input[type=radio] + label::after {
    content: "";
    position: absolute;
    display: inline-block;
    left: -21px;
    top: 5px;
    border-radius: 50%;
    width: 10px;
    height: 9px;
}
input[type=radio]:checked + label::after {
  background: #FF3D00;
  transition: background var(--animation-fast);
}
input[type=radio]:focus + label::before {
  box-shadow: 0 0px 8px #db3846;
}
@media (max-width: 992px) {
    .panel-input-error.input-error {
        bottom: -14px;
        left: 20px;
    }
}
</style>