<template>
	<ul class="progress">
	  <li class="progressItem" v-for="(item, index) in items" :key="index" :class="{ active: items[index] === current}"></li>
	</ul>
  </template>
  
  <script>
  export default {
	name: 'MinisiteRegisterProgress',
	props: {
	  items: {
		type: Array,
		required: true,
	  },
	  current: {
		type: String,
		required: true,
	  }
	},
  }
  </script>
  
  <style scoped>
  .progress {list-style: none;margin: 0;padding: 0;display: flex;align-items: center;border-radius: 8px;overflow: hidden;width: 263px;}
  .progressItem {background-color: var(--color-dark-pastel-green);width: 100%;height: 4px;}
  .progressItem.active ~ li {background-color: var(--color-pale-grey);}
  </style>
  